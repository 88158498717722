import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SingleImage = makeShortcode("SingleImage");
const Quote = makeShortcode("Quote");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "when-things-arent-okay-getting-professional-help"
    }}>{`When things aren't okay: Getting professional help`}</h1>
    <SingleImage smallGridSize={8} gridSize={6} src="/images/m10/45.svg" alt="Professional Help Home Image" mdxType="SingleImage" />
    <Quote quote="“When you have exhausted all possibilities, remember this: you haven't.”" author="Thomas Edison" mdxType="Quote" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      